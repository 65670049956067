<template>
  <section class="px-16 mt-8">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <section class="d-flex flex-column flex-sm-row justify-content-between">
          <h2 class="my-3">Talana / SAP</h2>
        </section>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" class="mb-2">
        <v-autocomplete
          v-model="filters.items"
          class="mt-3"
          @change="($event) => getTalanaSapData()"
          :items="[5, 10, 15, 20, 25, 100]"
          label="Items por Página"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="mb-2">
        <v-data-table
          :page.sync="filters.page"
          :headers="tableHeaders"
          :items="dataTalanaSap.items ? dataTalanaSap.items : []"
          item-key="code"
          :items-per-page="filters.items"
          class="elevation-1"
          mobile-breakpoint="993"
          hide-default-footer
          :loading="loading"
          no-data-text="No hay Datos para Mostrar"
          loading-text="Cargando Información... Espere Por favor"
        >
          <template v-slot:item.user="{ item }">
            <div class="text-left">
              {{ item.user }}
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            <div class="text-left">
              {{ item.description }}
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div class="text-center">
              {{ formatDateToHuman(item.created_at) }}
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="filters.page"
            :length="dataTalanaSap ? dataTalanaSap.pages : 1"
            :total-visible="8"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      tableHeaders: [
        {
          value: "description",
          text: "Descripción",
          sortable: false,
          width: "15%",
        },
        {
          value: "user",
          text: "Usuario",
          sortable: false,
          width: "5%",
        },
        {
          align: "center",
          value: "created_at",
          text: "Fecha",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  mounted() {
    this.getTalanaSapData();
  },
  methods: {
    ...mapActions({
      getAllDataTalanaSap: "common/getAllDataTalanaSap",
    }),
    async getTalanaSapData(page = 1) {
      let self = this;
      self.loading = true;
      self.filters.page = page;
      await self.getAllDataTalanaSap({
        ...self.filters,
      });
      self.loading = false;
    },
    formatDateToHuman(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      dataTalanaSap: "common/dataTalanaSap",
    }),
  },
  watch: {
    "filters.page": function (page) {
      this.getTalanaSapData(page);
    },
  },
};
</script>

<style></style>
